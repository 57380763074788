import React from 'react';
import { IItemRendererProps, Suggest } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import moment from 'moment-timezone';

import { CardSuggest } from '@resources/cards/_card-suggest';
import { FORMAT_DATE_SHORT } from '@resources/helpers/datetime';

export const LoginSuggestCom = Suggest.ofType<string>();

export const loginSuggestItemRender = (item: string, p: IItemRendererProps) => {
    return (
        <MenuItem
            key={p.index}
            text={item}
            onClick={p.handleClick}
        />
    );
};