import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Flex } from 'reflexbox';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { navigate } from 'gatsby-link';

import { LoginSuggestCom, loginSuggestItemRender } from '@components/login-suggest-com';
import { useCanNav } from '@components/ui/use-can-nav';
import { useRandomId } from '@components/ui/use-random-id';

import { Layout } from '@containers/layout';

import { GlobalState } from '@resources/reducers';
import { authLogin } from '@resources/users/actions';
import { selectLogins } from '@resources/users/selectors';
import { UsersActions } from '@resources/actions/_state';
import { selectUsersActions } from '@resources/actions/selectors';

type Props = {
    logins: string[];
    usersActions: UsersActions;
};

export default connect(mapStateToProps)((props: Props) => {
    const dispatch = useDispatch();

    // Редирект на главную после входа
    const [] = useCanNav(props.usersActions.auth_login.success, () => {
        navigate('/');
    });

    const [loginId] = useRandomId('login');
    const [passwordId] = useRandomId('password');

    const [login, setLogin] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const onLogin = () => {
        dispatch(authLogin({ login, password }));
    };

    return (
        <Layout isLoading={false} title="Вход">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onLogin();
                }}>
                <Flex width={['20%']} flexDirection="column">
                    <FormGroup label="Логин" labelFor={loginId}>
                        <LoginSuggestCom
                            items={props.logins}
                            itemRenderer={loginSuggestItemRender}
                            resetOnSelect={false}
                            closeOnSelect={true}
                            onItemSelect={(item) => setLogin(item)}
                            onQueryChange={(item) => setLogin(item)}
                            inputValueRenderer={(item) => item}
                            fill={true}
                            inputProps={{
                                id: loginId,
                                value: login,
                                fill: true,
                                placeholder: 'Логин',
                                leftIcon: 'user',
                                autoComplete: loginId,
                            }}
                        />
                    </FormGroup>
                    <FormGroup label="Пароль" labelFor={passwordId}>
                        <InputGroup
                            id={passwordId}
                            placeholder="000000"
                            name="password"
                            leftIcon="key"
                            value={password}
                            autoComplete={passwordId}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormGroup>
                    <Button type="submit" intent={Intent.SUCCESS} onClick={onLogin}>
                        Войти
                    </Button>
                </Flex>
            </form>
        </Layout>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        logins: selectLogins(state),
        usersActions: selectUsersActions(state),
    };
}
